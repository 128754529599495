
import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';


import {createcontact } from '../../api/contact.api'

import './homepage_eng.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Phomepage_eng = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);

  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  
const monthLabels = [
      {
        name: 'Enero',
      },
      {
        name: 'Febrero',
      },
      {
        name: 'Marzo',
      },
      {
        name: 'Abril',
      },
      {
        name: 'Mayo',
      },
      {
        name: 'Junio',
      },
      {
        name: 'Julio',
      },
      {
        name: 'Agosto',
      },
      {
        name: 'Septiembre',
      },
      {
        name: 'Octubre',
      },
      {
        name: 'Noviembre',
      },
      {
        name: 'Diciembre',
      },
    ];

const setStatus = (date, initialDate) => {
  if (initialDate.getFullYear() < date.getFullYear()) {
    return 'last';
  }
  if (
    initialDate.getFullYear() === date.getFullYear() && date.getMonth() < initialDate.getMonth()
  ) {
    return 'last';
  }
  if (initialDate.getFullYear() > date.getFullYear()) {
    return 'next';
  }
  if (
    initialDate.getFullYear() === date.getFullYear() && date.getMonth() > initialDate.getMonth()
  ) {
    return 'next';
  }
  return 'local';
};

const generateDaysMonth = (date) => {
  const arrayDays = [];
  const lastDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const nextDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  lastDate.setMonth(date.getMonth() - 1);
  nextDate.setMonth(date.getMonth() + 1);
  const initialDate = new Date(date.getFullYear(), date.getMonth(), 1);
  for (let row = 0; row < 6; row++) {
    const arrayRows = [];
    for (let colum = 0; colum < 7; colum++) {
      if (initialDate.getDay() === colum) {
        const status = setStatus(date, initialDate);
        arrayRows.push({
          date: new Date(initialDate.getFullYear(), initialDate.getMonth(), initialDate.getDate()),
          status,
        });
        initialDate.setDate(initialDate.getDate() + 1);
      } else {
        initialDate.setDate(initialDate.getDate() - initialDate.getDay());
        const status = setStatus(date, initialDate);
        arrayRows.push({
          date: new Date(initialDate.getFullYear(), initialDate.getMonth(), initialDate.getDate()),
          status,
        });
        initialDate.setDate(initialDate.getDate() + 1);
      }
    }
    arrayDays.push(arrayRows);
  }
  return arrayDays;
};

const generateClassStatus = (status) => {
  if (status === 'last') {
    return 'gjs-jsCalendar-previous';
  }
  if (status === 'next') {
    return 'gjs-jsCalendar-next';
  }
  return '';
};
    

const PluginCalendar_iw9lfx = () => {
  const [now, setNow] = useState(new Date());
  const [activeMonth, setActiveMonth] = useState(now.getMonth());
  const [selectedDate, setSelectedDate] = useState();
  const [selectedHour, setSelectedHour] = useState('');
  const [listHoursAm, setListHoursAm] = useState([]);
  const [listHoursPm, setListHourPm] = useState([]);
  const [arrayDays, setArrayDays] = useState([]);
  useEffect(() => {
    // ordenando la visualización del componente
    setTimeout(() => {
      const calendar = document.getElementsByClassName('calendarTable');
      if (calendar.length > 0) {
        calendar[0].style.display = 'block';
      }
      const showSchedule = document.getElementsByClassName('scheduleBasicStyle');
      if (showSchedule.length > 0) {
        showSchedule[0].style.display = 'none';
      }
      const formSchedule = document.getElementsByClassName('FormCalendarStyle');
      if (formSchedule.length > 0) {
        formSchedule[0].style.display = 'none';
      }
    }, 1500);
  }, []);
  useEffect(() => {
    const newArrayDays = generateDaysMonth(now);
    setArrayDays(newArrayDays);
  }, [now]);
  const loftychangeNextMonth = () => {
    if (activeMonth < monthLabels.length - 1) {
      setActiveMonth(activeMonth + 1);
      setNow(new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()));
    } else {
      setActiveMonth(0);
      setNow(new Date(now.getFullYear() + 1, 0, now.getDate()));
    }
  }
  const loftychangeLastMonth = () => {
    if (activeMonth > 0) {
      setActiveMonth(activeMonth - 1);
      setNow(new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()));
    } else {
      setActiveMonth(11);
      setNow(new Date(now.getFullYear() - 1, 11, now.getDate()));
    }
  }
  const loftyselectDate = (value) => {
    setSelectedDate(value.date);
    const calendar = document.getElementsByClassName('calendarTable');
    if (calendar.length > 0) {
      calendar[0].style.display = 'none';
    }
    const showSchedule = document.getElementsByClassName('scheduleBasicStyle');
    if (showSchedule.length > 0) {
      showSchedule[0].style.display = 'block';
    }
    const formSchedule = document.getElementsByClassName('FormCalendarStyle');
    if (formSchedule.length > 0) {
      formSchedule[0].style.display = 'none';
    }
    /* const res = await listSchedule({
      idPluginCalendar: component.calendarPlugin,
      selectedDate: value.date,
    });
    setListHours(res.data); */
  }
  const loftychangeHour = (hour) => {
    setSelectedHour(hour);
    const calendar = document.getElementsByClassName('calendarTable');
    if (calendar.length > 0) {
      calendar[0].style.display = 'none';
    }
    const showSchedule = document.getElementsByClassName('scheduleBasicStyle');
    if (showSchedule.length > 0) {
      showSchedule[0].style.display = 'none';
    }
    const formSchedule = document.getElementsByClassName('FormCalendarStyle');
    if (formSchedule.length > 0) {
      formSchedule[0].style.display = 'block';
    }
  }
  const loftysubmitCalendar = (e) => {
    const nameCalendarValue = e.target.nameInputCalendar?.value;
    const emailCalendarValue = e.target.emailInputCalendar?.value;
    const messageCalendarValue = e.target.messageInputCalendar?.value;
    const phoneCalendarValue = e.target.phoneInputCalendar?.value;
    const dirCalendarValue = e.target.dirInputCalendar?.value;
    try {
      /* await createMeeting({
        idPluginCalendar: component.calendarPlugin,
        loftyAddress: dirCalendarValue,
        loftyDate: selectedDate,
        loftyEmail: emailCalendarValue,
        loftyHours: selectedHour,
        loftyName: nameCalendarValue,
        loftyReason: messageCalendarValue,
        loftyTelephone: phoneCalendarValue,
      }); */
      alert('Se agrego el dato de manera exitosa');
    } catch (error) {
      if (error.info) {
        alert(error.info);
      } else {
        alert('Error al procesar la información');
      }
    }
  }
  return (
    <div
      className="auto-jsCalendar gjs-jsCalendar "
      id="iw9lfx"
    >
    				
      <table className="calendarTable " id="i0q3sk">
        
      <thead className="" id="">
        
      <tr className="gjs-jsCalendar-title-row " id="undefined">
        
      <th
        className="gjs-jsCalendar-title "
        id=""
        colSpan="7"
      >
      
      <div id="iqpgih" className="">
      
      <p className="" id="ibitak">
        <span>Select a day</span>
      </p>
      
      </div>
      
      <div id="ifjbrc" className="">
      
      <div
        className="gjs-jsCalendar-title-name "
        id=""
      >
      {monthLabels[activeMonth]?.name}
      </div>
      
      </div>
      
      <div id="ii61x5" className="">
      
      <div id="" className="gjs-jsCalendar-title-left ">
      
      <button
        id=""
        type="button"
        className="gjs-jsCalendar-nav-left "
        onClick={() => {
          loftychangeLastMonth();
        }}
      />
      
      </div>
      
      <div id="" className="gjs-jsCalendar-title-right ">
      
      <button
        id=""
        type="button"
        className="gjs-jsCalendar-nav-right "
        onClick={() => {
          loftychangeNextMonth();
        }}
      />
      
      </div>
      
      </div>
      
      </th>
      
      </tr>
      
      <tr className="gjs-jsCalendar-week-days " id="undefined">
        
      <th
        className=""
        id=""
        colSpan=""
      >
      <span>S</span>
      </th>
      
      <th
        className=""
        id=""
        colSpan=""
      >
      <span>M</span>
      </th>
      
      <th
        className=""
        id=""
        colSpan=""
      >
      <span>T</span>
      </th>
      
      <th
        className=""
        id=""
        colSpan=""
      >
      <span>W</span>
      </th>
      
      <th
        className=""
        id=""
        colSpan=""
      >
      <span>T</span>
      </th>
      
      <th
        className=""
        id=""
        colSpan=""
      >
      <span>F</span>
      </th>
      
      <th
        className=""
        id=""
        colSpan=""
      >
      <span>S</span>
      </th>
      
      </tr>
      
      </thead>
      
      <tbody className="" id="undefined">
        {
          arrayDays.map((item) => (
            <tr>
              {
                item.map((value) => (
                  <td
                    onClick={() => {
                      loftyselectDate(value);
                    }}
                    className={generateClassStatus(value.status)}
                  >
                    {value.date.getDate()}
                  </td>
                ))
              }
            </tr>
          ))
        }
      </tbody>
      
      </table>
      		
    <div id="i37vrx" className="scheduleBasicStyle ">
    
      <div id="ic4brd" className="">
      
      <div id="i2zudm" className="">
      <span>Select an hour:</span>
      </div>
      
      </div>
      
      <div id="i5ufu7" className="">
      
    <div id="iwyyfn" className="">
    
    <div id="i9flke" className="">
    
      <div id="iqvrih" className="">
      <span>AM</span>
      </div>
      
      <div id="i0hahr" className="">
      
      <img
        className=""
        id="is84w3"
        src="https://assetsprojects.s3.amazonaws.com/404p7ohlgqp9v61.png"
        alt="undefined"
      />
      
      </div>
      
      <div
        className=""
        id="i4yqxo"
      >
        {
          listHoursAm.map((item) => (
            <button
              type="button"
              className="gjs-scheduleButton"
              key={item}
            >
              {item}
            </button>
          ))
        }
      </div>
      
    </div>
    
      <div id="i8m1n3" className="">
      
      </div>
      
    <div id="ikzwto" className="">
    
      <div id="iryuk2" className="">
      <span>PM</span>
      </div>
      
      <div id="i1u7g7" className="">
      
      <img
        className=""
        id="in3wic"
        src="https://assetsprojects.s3.amazonaws.com/404p7ohlgv7o6am.png"
        alt="undefined"
      />
      
      </div>
      
      <div
        className=""
        id="in5l8g"
      >
        {
          listHoursPm.map((item) => (
            <button
              className="gjs-scheduleButton"
              key={item}
            >
              {item}
            </button>
          ))
        }
      </div>
      
    </div>
    
    </div>
    
      </div>
      
    </div>
    		
      <form
        id="i8xhit"
        className="FormCalendarStyle "
        
      >
        
      <div id="ieo9yj" className="">
      
      <p className="" id="ioh78o">
        <span>Reserving: Acosta's Lawfirm & Associates</span>
      </p>
      
      <p className="" id="iii10p">
        <span>2022 May 13th 1:30 pm - 2:30 pm America/Tegucigalpa</span>
      </p>
      
      </div>
      
      <div id="ic6qaz" className="">
      
      <div id="i9pj5l" className="">
      
      <div id="iwh5wu" className="">
      <span>Nombre</span>
      </div>
      
      <div id="ibwxpz" className="">
      
      <img
        className=""
        id="ijfvy8"
        src="https://assetsprojects.s3.amazonaws.com/404p7ohlgv9lhir.png"
        alt="undefined"
      />
      
      <input
        name="nameInputCalendar"
        type="text"
        placeholder="Name*"
        className=""
        id="ivv32l"
      />
      
      </div>
      
      <div id="i9sp8y" className="">
      
      </div>
      
      </div>
      
      <div id="id3itn" className="">
      
      <div id="i07s2f" className="">
      <span>Correo</span>
      </div>
      
      <div id="ivsecu" className="">
      
      <img
        className=""
        id="i1k3xh"
        src="https://assetsprojects.s3.amazonaws.com/404p7ohlgva62bh.png"
        alt="undefined"
      />
      
      <input
        name="emailInputCalendar"
        type="email"
        placeholder="E-Mail*"
        className=""
        id="iu3w7u"
      />
      
      </div>
      
      <div id="izgcv3" className="">
      
      </div>
      
      </div>
      
      <div id="iaxpig" className="">
      
      <div id="ijcfjt" className="">
      <span>Mensaje</span>
      </div>
      
      <div id="i391qg" className="">
      
      <img
        className=""
        id="i4xbwk"
        src="https://assetsprojects.s3.amazonaws.com/404p7ohlgva6lk0.png"
        alt="undefined"
      />
      
      <input
        name="messageInputCalendar"
        type="text"
        placeholder="Message"
        className=""
        id="ij7hzd"
      />
      
      </div>
      
      <div id="is6opa" className="">
      
      </div>
      
      </div>
      
      <div id="i4xpep" className="">
      
      <div id="iz7n8z" className="">
      <span>Teléfono</span>
      </div>
      
      <div id="ip216p" className="">
      
      <img
        className=""
        id="ikahdi"
        src="https://assetsprojects.s3.amazonaws.com/404p7ohlgva6sqk.png"
        alt="undefined"
      />
      
      <input
        name="phoneInputCalendar"
        type="text"
        placeholder="Phone Number*"
        className=""
        id="if44c9"
      />
      
      </div>
      
      <div id="ic13wi" className="">
      
      </div>
      
      </div>
      
      <div id="iogpck" className="">
      
      <div id="ibndst" className="">
      <span>Dirección</span>
      </div>
      
      <div id="iksuas" className="">
      
      <img
        className=""
        id="ivuvwv"
        src="https://assetsprojects.s3.amazonaws.com/404p7ohlgva706h.png"
        alt="undefined"
      />
      
      <input
        name="dirInputCalendar"
        type="text"
        placeholder="Address*"
        className=""
        id="igt5el"
      />
      
      </div>
      
      <div id="ityts7" className="">
      
      </div>
      
      </div>
      
      <button
        type="submit"
        name=""
        id="i5upgl"
        className=""
        
      >
      <span>Reserve Appointment
        </span>
      </button>
      
      </div>
      
      </form>
      
    </div>
  );
}
  
  
  return (
    <div id="id62fa735bfc8b2a0012c15bbc" >

      <div id="init" className="">
      
      <div id="i114ep" className="">
      
      <div id="i0a5qg" className="">
      
      <div id="ica844" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#init"
        type=""
      >
        <span>Home</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#about"
        type=""
      >
        <span>About</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#team"
        type=""
      >
        <span>Team</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#contact"
        type=""
      >
        <span>Contact</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Twitter</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="it11um" className="">
      
      <p className="" id="i6as7l">
        
      <span className="" id="ix3ge1">
        <span>Acosta's</span>
      </span>
      
      </p>
      
      <img
        className=""
        id="i4m3vk"
        src="https://assetsprojects.s3.amazonaws.com/406450il5i9ewct.png"
        alt="undefined"
      />
      
      <p className="" id="icwl38">
        
      <span className="" id="igvz8y">
        <span>Lawfirm & Associates</span>
      </span>
      
      </p>
      
      </div>
      
      <div id="ilkxqj" className="">
      
      <p className="" id="isxpy4">
        
      <a
        target="_blank"
        className="link "
        id=""
        href="/"
        type=""
      >
        <span>Spanish</span>
      </a>
      
      </p>
      
      <p className="" id="i44cqh">
        
      </p>
      
      <p className="" id="irl48k">
        
      <a
        target=""
        className="link "
        id=""
        href="/home"
        type=""
      >
        <span>English</span>
      </a>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ig01q" className="">
      
      <div id="i9k4z" className="gjs-row ">
      
      <div id="ir2t8" className="gjs-cell ">
      
          <div id="is7b4" className="">
            <iframe
              title="Video Youtube"
              src="https://www.youtube.com/embed/3ioQw_KOr9g?"
              allowFullScreen="undefined"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </div>
          
      </div>
      
      <div id="i992y" className="gjs-cell ">
      
      <div id="ivtko" className="">
      
    <div id="" className="gjs-container container ">
    
      <div id="ipaf7" className="">
      
      <p className="" id="io9ap">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="imuqh">
        <span>Ethics, Honesty, Responsibility, Efficiency, Loyalty, Justice, Integrity, Respect, and Confidentiality</span>
      </span>
      
      </p>
      
      </div>
      
      <div id="i4yxi" className="">
      
      </div>
      
    </div>
    
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iiwh" className="">
      
      <div id="i4l02" className="gjs-row ">
      
      <div id="iwhfk" className="gjs-cell ">
      
      <p className="" id="iapoz">
        <span>Welcome to </span>
      </p>
      
      <p className="" id="irnm4">
        <span>Acosta's Law Firm</span>
      </p>
      
      </div>
      
      <div id="i1gx9" className="gjs-cell ">
      
      <div id="i959v7" className="">
      
      </div>
      
      <p className="" id="i39pw">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="i8lnsg">
        <span>Acosta’s Law Firm assisted the renowned lawyers and notaries David Alfonso Velasquez Mejia and Harold Arturo Lopez in the city of Tegucigalpa from 1994 to 2004. After closing that chapter, we started a new one by moving our headquarters to the city of Roatan; maintaining our principles of ethics, solidarity, integrity, respect, and loyalty is the key to keep a bright and successful future with satisfied customers.</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="about" className="">
      
      <div id="i8il5j" className="">
      
      <div id="ir0y3r" className="">
      
      <p className="" id="i930wg">
        <span>Ab</span>
      </p>
      
      </div>
      
      <div id="ixsm5i" className="">
      
      <p className="" id="iim50b">
        <span>- Initio</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ittzfj" className="gjs-row ">
      
      <div id="ixzslc" className="gjs-cell ">
      
      <div id="ifh71k" className="">
      
      <img
        className=""
        id="i6xc2n"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h0x0ac.png"
        alt="undefined"
      />
      
      <p className="" id="ieaqui">
        <span>Justice</span>
      </p>
      
      <p className="" id="isaitc">
        <span>Moral principle that inclines to act and judge, respecting the truth and giving to each what is due.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iafvpq" className="gjs-cell ">
      
      <div id="ibwuaq" className="">
      
      <img
        className=""
        id="io7f8r"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h1frca.png"
        alt="undefined"
      />
      
      <p className="" id="ig0tug">
        <span>Law</span>
      </p>
      
      <p className="" id="ib6yhk">
        <span>It is a declaration of the sovereign will, which, manifested in the manner prescribed by the constitution, commands, prohibits or allows.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ijybbx" className="gjs-cell ">
      
      <div id="i5fqoc" className="">
      
      <img
        className=""
        id="igb0i2"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h4uyhr.png"
        alt="undefined"
      />
      
      <p className="" id="iauejp">
        <span>Right</span>
      </p>
      
      <p className="" id="i9c1xx">
        <span>Right is the set of norms that impose duties and norms that confer powers, that establish the bases of social coexistence and whose purpose is to provide all members of society with the minimum of security, certainty, equality, freedom, and justice.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ifn8qn" className="gjs-cell ">
      
      <div id="iif82v" className="">
      
      <img
        className=""
        id="id6nnm"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h7ehwj.png"
        alt="undefined"
      />
      
      <p className="" id="ihokcc">
        <span>Confidentiality</span>
      </p>
      
      <p className="" id="i7b7xz">
        <span>it is the feature or quality of those events, facts or actions that are confidential, secret or subject to a process of discretion on the part of those involved.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ivbdjv" className="gjs-cell ">
      
      <div id="i8xmxg" className="">
      
      <img
        className=""
        id="ioj89n"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h8hqhg.png"
        alt="undefined"
      />
      
      <p className="" id="iwjy4v">
        <span>Notary</span>
      </p>
      
      <p className="" id="ifgsi5">
        <span>Legal professional with a public faith character, authorized by the State to record the creation, transmission, modification or termination or resolution of acts, contracts and matters or businesses in which he intervenes at the request or request of the interested parties or by provision of the law.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ih1xin" className="gjs-cell ">
      
      <div id="iyf67" className="">
      
      <img
        className=""
        id="id3u8"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h8saby.png"
        alt="undefined"
      />
      
      <p className="" id="ie17j">
        <span>Integrity</span>
      </p>
      
      <p className="" id="iwl9yl">
        <span>A person of integrity is one who always does the right thing; that does everything that it considers good for itself without affecting the interests of other individuals.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iky5hi" className="gjs-cell ">
      
      <div id="i1uoff" className="">
      
      <p className="" id="itxqqt">
        <span>Conjunto de preceptos de caráter moral que debe siempre estar presentes en el hacer notarial del profesional autorizado para ejercer la función pública del Notariado.</span>
      </p>
      
      <img
        className=""
        id="i9gtav"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h958qv.png"
        alt="undefined"
      />
      
      <p className="" id="i2yiun">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="i3vw3r">
        <span>Ethics</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="ikswhd" className="gjs-cell ">
      
      <div id="i97qdp" className="">
      
      <img
        className=""
        id="ipxuf7"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h9n404.png"
        alt="undefined"
      />
      
      <p className="" id="idhw1w">
        <span>Responsibility</span>
      </p>
      
      <p className="" id="i7dq9v">
        <span>It is a value and an ethical practice, since it impacts on family, academic, work and civic life.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i83dhi" className="gjs-cell ">
      
      <div id="i000fi" className="">
      
      <img
        className=""
        id="iojvny"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5haj3fc.png"
        alt="undefined"
      />
      
      <p className="" id="ismbik">
        <span>Truthfulness</span>
      </p>
      
      <p className="" id="i96hmo">
        <span>It is a positive moral value that seeks the truth.</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="team" className="">
      
      <div id="ipiytl" className="">
      
      <div id="iqu2rt" className="">
      
      <p className="" id="ibcvzj">
        <span>Our</span>
      </p>
      
      </div>
      
      <div id="ipxxa2" className="">
      
      <p className="" id="iyabfu">
        <span>Team</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iam2ci" className="gjs-row ">
      
      <div id="itclbu" className="gjs-cell ">
      
      <div id="ingz1b" className="">
      
      <div id="inp4q1" className="gjs-row ">
      
      <div id="i3kjf6" className="gjs-cell ">
      
      <img
        className=""
        id="ihzsor"
        src="https://assetsprojects.s3.amazonaws.com/406450il5i9ewct.png"
        alt="undefined"
      />
      
      <div id="isqixk" className="">
      
      </div>
      
      </div>
      
      <div id="ixyrcl" className="gjs-cell ">
      
      <div id="ijaal3" className="">
      
      <p className="" id="iw9tdw">
        <span>Mario Acosta</span>
      </p>
      
      <p className="" id="iie9mm">
        <span>Notarial Matters</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iwoxtl" className="">
      
      <div id="i0j0u4" className="">
      
      <div id="itmjq2" className="">
      
      <img
        className=""
        id="iorngw"
        src="https://assetsprojects.s3.amazonaws.com/406450il5iacbqn.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ij4jng" className="">
      
      <p className="" id="i2vhuk">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ie1g45">
        <span>+504 9551-0686
</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ihw0ie" className="gjs-cell ">
      
      <div id="inq8p6" className="">
      
      <div id="id8tik" className="gjs-row ">
      
      <div id="i8z5yi" className="gjs-cell ">
      
      <img
        className=""
        id="io4wwo"
        src="https://assetsprojects.s3.amazonaws.com/406450il5i9ewct.png"
        alt="undefined"
      />
      
      <div id="ivdyzy" className="">
      
      </div>
      
      </div>
      
      <div id="ij3lyh" className="gjs-cell ">
      
      <div id="isd8oi" className="">
      
      <p className="" id="iwvz3g">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="iqnw8z">
        <span>Romario Acosta</span>
      </span>
      
      </p>
      
      <p className="" id="ifenji">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="i6zl4l">
        <span>Administrative and Notarial Matters
</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="isf544" className="">
      
      <div id="ib4qm6" className="">
      
      <div id="iqmdlg" className="">
      
      <img
        className=""
        id="ibjisg"
        src="https://assetsprojects.s3.amazonaws.com/406450il5iacbqn.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iraiys" className="">
      
      <p className="" id="if11mj">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="i30szm">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      <span> +504 9936-2872
</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ilr8fp" className="">
      
      <div id="ik4y1o" className="">
      
      <div id="ios5on" className="">
      
      <p className="" id="ixdbtg">
        <span>Our</span>
      </p>
      
      </div>
      
      <div id="iho3im" className="">
      
      <p className="" id="iqu8rd">
        <span>Services</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i1vxgl" className="gjs-row ">
      
      <div id="if2sr8" className="gjs-cell ">
      
      <div id="i4bd9p" className="">
      
      <div id="ipl52i" className="">
      
      </div>
      
      <div id="i4b4t4" className="">
      
      <p className="" id="irtck9">
        <span>Notarial Matters</span>
      </p>
      
      <div id="ionvnj" className="">
      
        <a
          target="_blank"
          className=""
          id="iz6ev7"
          href="/services_en"
        >
          
        </a>
        
      </div>
      
      <div id="iveut6" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i12igg" className="">
      
      <div id="i10f5f" className="">
      
      </div>
      
      <div id="ir1615" className="">
      
      <p className="" id="idvnf7">
        <span>Administrative Matters in General</span>
      </p>
      
      <div id="iynw1a" className="">
      
        <a
          target="_blank"
          className=""
          id="iesdb6"
          href="/services_en"
        >
          
        </a>
        
      </div>
      
      <div id="iobiu6" className="">
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i8w3cx" className="gjs-cell ">
      
      <div id="it97iq" className="">
      
      <div id="iyaah8" className="">
      
      </div>
      
      <div id="i93oc6" className="">
      
      <p className="" id="igmw4t">
        <span>Judicial Matters</span>
      </p>
      
      <div id="ille9s" className="">
      
        <a
          target="_blank"
          className=""
          id="ijkf9t"
          href="/services_en"
        >
          
        </a>
        
      </div>
      
      <div id="ig1ymb" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="idkulj" className="">
      
      <div id="i456ma" className="">
      
      </div>
      
      <div id="icwa41" className="">
      
      <p className="" id="i00ijp">
        <span>Family Matters</span>
      </p>
      
      <div id="inoj78" className="">
      
        <a
          target="_blank"
          className=""
          id="iosw1z"
          href="/services_en"
        >
          
        </a>
        
      </div>
      
      <div id="izcoaj" className="">
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i3990z" className="">
      
      <div id="ieajah" className="">
      
      <div id="iy8gl1" className="">
      
      <p className="" id="ipo1c9">
        <span>Make an</span>
      </p>
      
      </div>
      
      <div id="ivvhgh" className="">
      
      <p className="" id="irewm9">
        <span>Appointment</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ips5c9" className="">
      
      <div id="ivwzi2" className="">
      <PluginCalendar_iw9lfx />
      <div id="i2k0wd" className="">
      
      <p className="" id="i0n2pg">
        <span>Time Zone: America/Tegucigalpa</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ic68xh" className="">
      
      <div id="iru0nz" className="">
      
      <div id="ic3h3i" className="">
      
      <p className="" id="ie2nuf">
        
    <div id="" className="">
    <span>E-mail:</span>
    </div>
    
    <div id="" className="">
    
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ii7lc7">
        <span>info@acostaslawfirm.com</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="ifxwi5">
        
      <br className="" id="" />
      
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="i9kjvc">
        <span>Telephone:</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="i3oxbc">
        <span>+504 9936-2872, +504 9551-0686</span>
      </span>
      
    </div>
    
      </p>
      
      </div>
      
      <div id="izvpd5" className="">
      
      <p className="" id="iektgm">
        <span>Contact</span>
      </p>
      
        <div className="" id="ix7hpt">
          
      <form
        id="isjtam"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					createcontact({
						fullname: e.target.fullname.value,
						email: e.target.email.value,
						comment: e.target.comment.value,
					});
					alert("Se agrego de manera exitosa el registro");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="iranhl" className="">
      
      <label id="i4f95u" className="">
      <span>Full name *</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="fullname"
        type="text"
        placeholder=""
        className=""
        id="ijrmlv"
      />
      
      </div>
      
      <div id="ipcrm5" className="">
      
      </div>
      
      <div id="im6v92" className="">
      
      <label id="ieaen2" className="">
      <span>E-mail *</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="email"
        type="text"
        placeholder=""
        className=""
        id="i3vgjd"
      />
      
      </div>
      
      <div id="i5vjyg" className="">
      
      </div>
      
      <div id="iw1t6u" className="">
      
      <label id="i74usu" className="">
      <span>Message / Comment *</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="comment"
        type="text"
        placeholder=""
        className=""
        id="i5pz22"
      />
      
      </div>
      
      <div id="iomewc" className="">
      
      </div>
      
      <button
        type="submit"
        name=""
        id="ifru4c"
        className=""
        
      >
      <span>
                Submit
              </span>
      </button>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      <div id="contact" className="">
      
      <p className="" id="i3fvqj">
        <span>Contact</span>
      </p>
      
      <div id="i9p48h" className="">
      
      <p className="" id="i1lgcl">
        <span>Contact</span>
      </p>
      
        <div className="" id="iw1nu8">
          
      <form
        id="i6zvkj"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					createcontact({
						fullname: e.target.fullname.value,
						email: e.target.email.value,
						comment: e.target.comment.value,
					});
					alert("Se agrego de manera exitosa el registro");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="iz0uhn" className="">
      
      <label id="i4lhaq" className="">
      <span>Full name *</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="fullname"
        type="text"
        placeholder=""
        className=""
        id="izu0u1"
      />
      
      </div>
      
      <div id="irz3tk" className="">
      
      </div>
      
      <div id="iegld9" className="">
      
      <label id="i7i32j" className="">
      <span>E-mail *</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="email"
        type="text"
        placeholder=""
        className=""
        id="i1wco3"
      />
      
      </div>
      
      <div id="iwzm9m" className="">
      
      </div>
      
      <div id="i560gu" className="">
      
      <label id="i022xn" className="">
      <span>Message / Comment *</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="comment"
        type="text"
        placeholder=""
        className=""
        id="ippk3u"
      />
      
      </div>
      
      <div id="ib0cpq" className="">
      
      </div>
      
      <button
        type="submit"
        name=""
        id="icphip"
        className=""
        
      >
      <span>
                Submit
              </span>
      </button>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      </div>
      
      <div id="i123kk" className="">
      
      <iframe
        title="Mapa"
        className=""
        id="ijhnci"
        frameBorder="0"
        src="https://maps.google.com/maps?&q=Bojangles, McNab Plaza, main street, French Harbour&z=17&t=q&output=embed"
      />
      
      </div>
      
      <div id="i8bw3" className="">
      
      <div id="ic4k0a" className="">
      
      <div id="idapj2" className="">
      
      <img
        className=""
        id="inj60i"
        src="https://assetsprojects.s3.amazonaws.com/406450il5i9ewct.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ipog2k" className="">
      
      <div id="il59zj" className="">
      
      </div>
      
      <p className="" id="iajh9o">
        <span>Acosta's</span>
    <div id="" className="">
    <span>Lawfirm</span>
    </div>
    
    <div id="" className="">
    <span>& Associates</span>
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="i0exw3" className="">
      
      <p className="" id="ip30xz">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ivovhk">
        <span>Roatán,  Islas de la Bahía </span>
      <br className="" id="" />
      <span> Honduras</span>
      <br className="" id="" />
      <span>Bojangles, McNab Plaza, main street, French Harbour</span>
      <br className="" id="" />
      <span>Copyright © Acosta Law Firm 2022. All Rights Reserved.</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Phomepage_eng);
  