
import React from 'react';

import {connect} from 'react-redux';



import './servicepage.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pservicepage = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id62ceef00323c4d00120bd76d" >

      <div id="iv2k1h" className="">
      
      <div id="i84cpo" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className=""
        id="menu__toggle"
      />
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target="_blank"
        className="menu__item "
        id=""
        href="/#init"
        type=""
      >
        <span>Home</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target="_blank"
        className="menu__item "
        id=""
        href="/#about"
        type=""
      >
        <span>About</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target="_blank"
        className="menu__item "
        id=""
        href="/#team"
        type=""
      >
        <span>Team</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target="_blank"
        className="menu__item "
        id=""
        href="/#contact"
        type=""
      >
        <span>Contact</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Twitter</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i6z5l7" className="">
      
      <p className="" id="iqb6v5">
        
      <span className="" id="ifwhkg">
        <span>Acosta's</span>
      </span>
      
      </p>
      
      <img
        className=""
        id="ik785t"
        src="https://assetsprojects.s3.amazonaws.com/406450il5i9ewct.png"
        alt="undefined"
      />
      
      <p className="" id="iqj981">
        
      <span className="" id="inaxa7">
        <span>Lawfirm & Associates</span>
      </span>
      
      </p>
      
      </div>
      
      <div id="i65c83" className="">
      
      <p className="" id="ilmqln">
        
      <a
        target=""
        className="link "
        id=""
        href="/service"
        type=""
      >
        <span>Español</span>
      </a>
      
      </p>
      
      <p className="" id="ixyf5b">
        
      </p>
      
      <p className="" id="ih7vif">
        
      <a
        target="_blank"
        className="link "
        id=""
        href="/services_en"
        type=""
      >
        <span>Ingles</span>
      </a>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="i8fj" className="">
      
      <div id="itt4x" className="">
      
      <div id="i8dn6" className="">
      
      <p className="" id="i88ni3">
        <span>Nuestros</span>
      </p>
      
      </div>
      
      <div id="i7eni" className="">
      
      <p className="" id="im1qvd">
        <span>Servicios</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iulh4" className="">
      
      <div id="ilcz9l" className="">
      
      </div>
      
      <div id="iskol" className="">
      
      <div id="i5irz" className="">
      
      <div id="ivggr" className="">
      
      <p className="" id="ivsc7">
        <span>Asuntos Notariales</span>
      </p>
      
      <div id="i47ze" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ixflv" className="">
      
      <p className="" id="iafs7">
        <span>Civiles</span>
      </p>
      
      <div id="ib9mt" className="">
      
      </div>
      
      <div id="iu9ft" className="">
      
      <p className="" id="if3uf">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ipmi3">
        <span>Escrituras de compraventa.
</span>
      <br className="" id="" />
      <span>Promesas de Venta.
</span>
      <br className="" id="" />
      <span>Hipoteca y cancelación de hipoteca.
</span>
      <br className="" id="" />
      <span>Donaciones.</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="i2tig6" className="">
      
      <p className="" id="i0vjo">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="i32zid">
        <span>Mercantiles</span>
      </span>
      
      </p>
      
      <div id="iyw7tj" className="">
      
      </div>
      
      <div id="ioz3nl" className="">
      
      <p className="" id="ia5u8r">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="io82u4">
        <span>Constitución, Transformación o Disolución de Sociedades (Corporaciones) (Aumento y Reducción de Capital).
</span>
      <br className="" id="" />
      <span>Actas notariales en general.
</span>
      <br className="" id="" />
      <span>Testamentos.
</span>
      <br className="" id="" />
      <span>Herencias y Legados.</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="iy8bhi" className="">
      
      <p className="" id="iyqvpl">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ijdt4k">
        <span>Actos de Jurisdicción Voluntaria en Sede Notarial.</span>
      </span>
      
      </p>
      
      <div id="isflqg" className="">
      
      </div>
      
      <div id="ip3ng3" className="">
      
      <p className="" id="is1op3">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="iv7url">
        <span>Rectificaciones de Inscripción en el Registro Civil.
</span>
      <br className="" id="" />
      <span>Informaciones Ad-Perpetuam.
</span>
      <br className="" id="" />
      <span>Matrimonios (Autorización de Matrimonios dentro y fuera de Honduras, Capitulaciones Matrimoniales).
</span>
      <br className="" id="" />
      <span>Divorcio por Mutuo Consentimiento.
</span>
      <br className="" id="" />
      <span>Unión y Separación de Hecho.
</span>
      <br className="" id="" />
      <span>Autorizaciones en General.
</span>
      <br className="" id="" />
      <span>Conciliación y Arbitraje.
</span>
      <br className="" id="" />
      <span>Partición de Bienes.
</span>
      <br className="" id="" />
      <span>Remedidas de Inmuebles</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="i37k8k" className="">
      
      <p className="" id="ip7h93">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="io761z">
        <span>Poderes: Otorgamiento, Ampliación, sustitución y Revocación</span>
      </span>
      
      </p>
      
      <div id="i5xsb3" className="">
      
      </div>
      
      <div id="in8bzj" className="">
      
      <p className="" id="ijxrik">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ixuibb">
        <span>Poder Especial.
</span>
      <br className="" id="" />
      <span>Poder General.
</span>
      <br className="" id="" />
      <span>Poder (Administración y Disposición).</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="isa4mk" className="">
      
      <p className="" id="iohh9g">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="i48ify">
        <span>Propiedad en Condominio </span>
      </span>
      
      </p>
      
      <div id="ijmwrn" className="">
      
      </div>
      
      <div id="irtdos" className="">
      
      <p className="" id="iqer13">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="i6da01">
        <span>Por constitución, afectación de la propiedad.
</span>
      <br className="" id="" />
      <span>Agrupaciones de inmuebles.
</span>
      <br className="" id="" />
      <span>Servidumbre (constitución).</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="il2jp" className="">
      
      </div>
      
      </div>
      
      <div id="i9d40k" className="">
      
      <div id="i1jsxj" className="">
      
      </div>
      
      <div id="ilparc" className="">
      
      <div id="i8ws06" className="">
      
      <div id="iz9qir" className="">
      
      <p className="" id="i545qf">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="io13ji">
        <span>Asuntos Administrativos en General
</span>
      </span>
      
      </p>
      
      <div id="ic11df" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iwkent" className="">
      
      <p className="" id="iaqq06">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="iod7bn">
        <span>Migratorios</span>
      </span>
      
      </p>
      
      <div id="i1groc" className="">
      
      </div>
      
      <div id="i71avp" className="">
      
      <p className="" id="ifccq8">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ix0e52">
        <span>Residencias.
</span>
      <br className="" id="" />
      <span>Permisos de Trabajo.
</span>
      <br className="" id="" />
      <span>Permisos Especiales de Permanencia en Honduras.
</span>
      <br className="" id="" />
      <span>Legalización de Empresas y Organizaciones Extranjeras.
</span>
      <br className="" id="" />
      <span>Personerías Jurídicas.
</span>
      <br className="" id="" />
      <span>Fundaciones.</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="iemnxq" className="">
      
      <p className="" id="idf33t">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ick86t">
        <span>Asuntos Tributarios, Administrativos y Contables.</span>
      </span>
      
      </p>
      
      <div id="i5r2wf" className="">
      
      </div>
      
      <div id="iph7ew" className="">
      
      <p className="" id="iqzcrj">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ixbale">
        <span>Impugnaciones y Demandas.
</span>
      <br className="" id="" />
      <span>Estado Financiero.
</span>
      <br className="" id="" />
      <span>Representación ante el SAR.</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iaqqq9" className="">
      
      <div id="i7alth" className="">
      
      </div>
      
      <div id="ihssh9" className="">
      
      <div id="i93i73" className="">
      
      <div id="i0ofpg" className="">
      
      <p className="" id="i2808z">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="idp5jx">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      <span>Asuntos Judiciales
</span>
      </span>
      
      </p>
      
      <div id="io5l7g" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i7x1zj" className="">
      
      <p className="" id="igof0t">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="iygu4k">
        <span>Divorcio en Sede Judicial.
</span>
      <br className="" id="" />
      <span>Demandas Civiles, Mercantiles, Laborales, Administrativas.
</span>
      <br className="" id="" />
      <span>Denuncias, Acusaciones y defensas en Materia Penal.
</span>
      <br className="" id="" />
      <span>Representación en Materia Administrativa y Judicial en General.</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="ipjf3a" className="">
      
      </div>
      
      </div>
      
      <div id="i7xfof" className="">
      
      <div id="i9b58g" className="">
      
      </div>
      
      <div id="isofoo" className="">
      
      <div id="i8wcay" className="">
      
      <div id="iqnwly" className="">
      
      <p className="" id="i7ne5g">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="izejfs">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      <span>Asuntos de Familia
</span>
      </span>
      
      </p>
      
      <div id="izmnq1" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i6zsql" className="">
      
      <p className="" id="i66e7f">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="i5o0ai">
        <span>Reconocimientos de Hijos.
</span>
      <br className="" id="" />
      <span>Matrimonio Civil.</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ijk5" className="">
      
      <iframe
        title="Mapa"
        className=""
        id="i0wk"
        frameBorder="0"
        src="https://maps.google.com/maps?&q=Bojangles, McNab Plaza, main street, French Harbour&z=17&t=q&output=embed"
      />
      
      </div>
      
      <div id="ixax" className="">
      
      <div id="if6id8" className="">
      
      <div id="ixamfy" className="">
      
      <img
        className=""
        id="iqx8xl"
        src="https://assetsprojects.s3.amazonaws.com/406450il5i9ewct.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i9tjsk" className="">
      
      <div id="il22am" className="">
      
      </div>
      
      <p className="" id="igni1j">
        <span>Acosta's</span>
    <div id="" className="">
    <span>Lawfirm </span>
    </div>
    
    <div id="" className="">
    <span>& Associates</span>
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="iacgmf" className="">
      
      <p className="" id="iu9123">
        
      <span className="" id="iaypss">
        <span>Roatán,  Islas de la Bahía </span>
      </span>
      
      <br className="" id="i8osim" />
      
      <span className="" id="i36x8h">
        <span> Honduras</span>
      </span>
      
      <br className="" id="i40iea" />
      
      <span className="" id="ie87k8">
        <span>Bojangles, McNab Plaza, main street, French Harbour</span>
      </span>
      
      <br className="" id="iha4e8" />
      
      <span className="" id="iaq2t7">
        <span>Copyright © Acosta Law Firm 2022. Todos Los Derechos Reservados</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Pservicepage);
  