
import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';


import {createcontact } from '../../api/contact.api'

import './pageinitesp.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Ppageinitesp = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);

  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  
const monthLabels = [
      {
        name: 'Enero',
      },
      {
        name: 'Febrero',
      },
      {
        name: 'Marzo',
      },
      {
        name: 'Abril',
      },
      {
        name: 'Mayo',
      },
      {
        name: 'Junio',
      },
      {
        name: 'Julio',
      },
      {
        name: 'Agosto',
      },
      {
        name: 'Septiembre',
      },
      {
        name: 'Octubre',
      },
      {
        name: 'Noviembre',
      },
      {
        name: 'Diciembre',
      },
    ];

const setStatus = (date, initialDate) => {
  if (initialDate.getFullYear() < date.getFullYear()) {
    return 'last';
  }
  if (
    initialDate.getFullYear() === date.getFullYear() && date.getMonth() < initialDate.getMonth()
  ) {
    return 'last';
  }
  if (initialDate.getFullYear() > date.getFullYear()) {
    return 'next';
  }
  if (
    initialDate.getFullYear() === date.getFullYear() && date.getMonth() > initialDate.getMonth()
  ) {
    return 'next';
  }
  return 'local';
};

const generateDaysMonth = (date) => {
  const arrayDays = [];
  const lastDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const nextDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  lastDate.setMonth(date.getMonth() - 1);
  nextDate.setMonth(date.getMonth() + 1);
  const initialDate = new Date(date.getFullYear(), date.getMonth(), 1);
  for (let row = 0; row < 6; row++) {
    const arrayRows = [];
    for (let colum = 0; colum < 7; colum++) {
      if (initialDate.getDay() === colum) {
        const status = setStatus(date, initialDate);
        arrayRows.push({
          date: new Date(initialDate.getFullYear(), initialDate.getMonth(), initialDate.getDate()),
          status,
        });
        initialDate.setDate(initialDate.getDate() + 1);
      } else {
        initialDate.setDate(initialDate.getDate() - initialDate.getDay());
        const status = setStatus(date, initialDate);
        arrayRows.push({
          date: new Date(initialDate.getFullYear(), initialDate.getMonth(), initialDate.getDate()),
          status,
        });
        initialDate.setDate(initialDate.getDate() + 1);
      }
    }
    arrayDays.push(arrayRows);
  }
  return arrayDays;
};

const generateClassStatus = (status) => {
  if (status === 'last') {
    return 'gjs-jsCalendar-previous';
  }
  if (status === 'next') {
    return 'gjs-jsCalendar-next';
  }
  return '';
};
    

const PluginCalendar_i2tank = () => {
  const [now, setNow] = useState(new Date());
  const [activeMonth, setActiveMonth] = useState(now.getMonth());
  const [selectedDate, setSelectedDate] = useState();
  const [selectedHour, setSelectedHour] = useState('');
  const [listHoursAm, setListHoursAm] = useState([]);
  const [listHoursPm, setListHourPm] = useState([]);
  const [arrayDays, setArrayDays] = useState([]);
  useEffect(() => {
    // ordenando la visualización del componente
    setTimeout(() => {
      const calendar = document.getElementsByClassName('calendarTable');
      if (calendar.length > 0) {
        calendar[0].style.display = 'block';
      }
      const showSchedule = document.getElementsByClassName('scheduleBasicStyle');
      if (showSchedule.length > 0) {
        showSchedule[0].style.display = 'none';
      }
      const formSchedule = document.getElementsByClassName('FormCalendarStyle');
      if (formSchedule.length > 0) {
        formSchedule[0].style.display = 'none';
      }
    }, 1500);
  }, []);
  useEffect(() => {
    const newArrayDays = generateDaysMonth(now);
    setArrayDays(newArrayDays);
  }, [now]);
  const loftychangeNextMonth = () => {
    if (activeMonth < monthLabels.length - 1) {
      setActiveMonth(activeMonth + 1);
      setNow(new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()));
    } else {
      setActiveMonth(0);
      setNow(new Date(now.getFullYear() + 1, 0, now.getDate()));
    }
  }
  const loftychangeLastMonth = () => {
    if (activeMonth > 0) {
      setActiveMonth(activeMonth - 1);
      setNow(new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()));
    } else {
      setActiveMonth(11);
      setNow(new Date(now.getFullYear() - 1, 11, now.getDate()));
    }
  }
  const loftyselectDate = (value) => {
    setSelectedDate(value.date);
    const calendar = document.getElementsByClassName('calendarTable');
    if (calendar.length > 0) {
      calendar[0].style.display = 'none';
    }
    const showSchedule = document.getElementsByClassName('scheduleBasicStyle');
    if (showSchedule.length > 0) {
      showSchedule[0].style.display = 'block';
    }
    const formSchedule = document.getElementsByClassName('FormCalendarStyle');
    if (formSchedule.length > 0) {
      formSchedule[0].style.display = 'none';
    }
    /* const res = await listSchedule({
      idPluginCalendar: component.calendarPlugin,
      selectedDate: value.date,
    });
    setListHours(res.data); */
  }
  const loftychangeHour = (hour) => {
    setSelectedHour(hour);
    const calendar = document.getElementsByClassName('calendarTable');
    if (calendar.length > 0) {
      calendar[0].style.display = 'none';
    }
    const showSchedule = document.getElementsByClassName('scheduleBasicStyle');
    if (showSchedule.length > 0) {
      showSchedule[0].style.display = 'none';
    }
    const formSchedule = document.getElementsByClassName('FormCalendarStyle');
    if (formSchedule.length > 0) {
      formSchedule[0].style.display = 'block';
    }
  }
  const loftysubmitCalendar = (e) => {
    const nameCalendarValue = e.target.nameInputCalendar?.value;
    const emailCalendarValue = e.target.emailInputCalendar?.value;
    const messageCalendarValue = e.target.messageInputCalendar?.value;
    const phoneCalendarValue = e.target.phoneInputCalendar?.value;
    const dirCalendarValue = e.target.dirInputCalendar?.value;
    try {
      /* await createMeeting({
        idPluginCalendar: component.calendarPlugin,
        loftyAddress: dirCalendarValue,
        loftyDate: selectedDate,
        loftyEmail: emailCalendarValue,
        loftyHours: selectedHour,
        loftyName: nameCalendarValue,
        loftyReason: messageCalendarValue,
        loftyTelephone: phoneCalendarValue,
      }); */
      alert('Se agrego el dato de manera exitosa');
    } catch (error) {
      if (error.info) {
        alert(error.info);
      } else {
        alert('Error al procesar la información');
      }
    }
  }
  return (
    <div
      className="auto-jsCalendar gjs-jsCalendar "
      id="i2tank"
    >
    				
      <table className="calendarTable " id="iy2ssd">
        
      <thead className="" id="">
        
      <tr className="gjs-jsCalendar-title-row " id="undefined">
        
      <th
        className="gjs-jsCalendar-title "
        id=""
        colSpan="7"
      >
      
      <div id="inwpsg" className="">
      
      <p className="" id="ifshji">
        <span>Seleccione una fecha</span>
      </p>
      
      </div>
      
      <div id="iubgxp" className="">
      
      <div
        className="gjs-jsCalendar-title-name "
        id=""
      >
      {monthLabels[activeMonth]?.name}
      </div>
      
      </div>
      
      <div id="i5li0e" className="">
      
      <div id="" className="gjs-jsCalendar-title-left ">
      
      <button
        id=""
        type="button"
        className="gjs-jsCalendar-nav-left "
        onClick={() => {
          loftychangeLastMonth();
        }}
      />
      
      </div>
      
      <div id="" className="gjs-jsCalendar-title-right ">
      
      <button
        id=""
        type="button"
        className="gjs-jsCalendar-nav-right "
        onClick={() => {
          loftychangeNextMonth();
        }}
      />
      
      </div>
      
      </div>
      
      </th>
      
      </tr>
      
      <tr className="gjs-jsCalendar-week-days " id="undefined">
        
      <th
        className=""
        id=""
        colSpan=""
      >
      <span>S</span>
      </th>
      
      <th
        className=""
        id=""
        colSpan=""
      >
      <span>M</span>
      </th>
      
      <th
        className=""
        id=""
        colSpan=""
      >
      <span>T</span>
      </th>
      
      <th
        className=""
        id=""
        colSpan=""
      >
      <span>W</span>
      </th>
      
      <th
        className=""
        id=""
        colSpan=""
      >
      <span>T</span>
      </th>
      
      <th
        className=""
        id=""
        colSpan=""
      >
      <span>F</span>
      </th>
      
      <th
        className=""
        id=""
        colSpan=""
      >
      <span>S</span>
      </th>
      
      </tr>
      
      </thead>
      
      <tbody className="" id="undefined">
        {
          arrayDays.map((item) => (
            <tr>
              {
                item.map((value) => (
                  <td
                    onClick={() => {
                      loftyselectDate(value);
                    }}
                    className={generateClassStatus(value.status)}
                  >
                    {value.date.getDate()}
                  </td>
                ))
              }
            </tr>
          ))
        }
      </tbody>
      
      </table>
      		
    <div id="ixptrx" className="scheduleBasicStyle ">
    
      <div id="i7z3fh" className="">
      
      <div id="i0b2rl" className="">
      <span>Seleccione una hora:</span>
      </div>
      
      </div>
      
      <div id="ig342v" className="">
      
    <div id="i5mxve" className="">
    
    <div id="intjon" className="">
    
      <div id="i8cr56" className="">
      <span>AM</span>
      </div>
      
      <div id="itv4jo" className="">
      
      <img
        className=""
        id="is1xiq"
        src="https://assetsprojects.s3.amazonaws.com/404p7ohlgqp9v61.png"
        alt="undefined"
      />
      
      </div>
      
      <div
        className=""
        id="ie1muh"
      >
        {
          listHoursAm.map((item) => (
            <button
              type="button"
              className="gjs-scheduleButton"
              key={item}
            >
              {item}
            </button>
          ))
        }
      </div>
      
    </div>
    
      <div id="iux1fx" className="">
      
      </div>
      
    <div id="ibmcug" className="">
    
      <div id="i9sld7" className="">
      <span>PM</span>
      </div>
      
      <div id="i2chwt" className="">
      
      <img
        className=""
        id="i3vltt"
        src="https://assetsprojects.s3.amazonaws.com/404p7ohlgv7o6am.png"
        alt="undefined"
      />
      
      </div>
      
      <div
        className=""
        id="iabqsk"
      >
        {
          listHoursPm.map((item) => (
            <button
              className="gjs-scheduleButton"
              key={item}
            >
              {item}
            </button>
          ))
        }
      </div>
      
    </div>
    
    </div>
    
      </div>
      
    </div>
    		
      <form
        id="iebmpw"
        className="FormCalendarStyle "
        
      >
        
      <div id="i6fu1w" className="">
      
      <p className="" id="ibqjiy">
        <span>Reservando: Acosta's Lawfirm & Associates</span>
      </p>
      
      <p className="" id="irzusf">
        <span>2022 May 13th 1:30 pm - 2:30 pm America/Tegucigalpa</span>
      </p>
      
      </div>
      
      <div id="if23rg" className="">
      
      <div id="iu3ch9" className="">
      
      <div id="if7cph" className="">
      <span>Nombre</span>
      </div>
      
      <div id="iq28iz" className="">
      
      <img
        className=""
        id="inbr8f"
        src="https://assetsprojects.s3.amazonaws.com/404p7ohlgv9lhir.png"
        alt="undefined"
      />
      
      <input
        name="nameInputCalendar"
        type="text"
        placeholder="Nombre*"
        className=""
        id="ivcg32"
      />
      
      </div>
      
      <div id="izxekx" className="">
      
      </div>
      
      </div>
      
      <div id="id2f0j" className="">
      
      <div id="i85p57" className="">
      <span>Correo</span>
      </div>
      
      <div id="is4k6l" className="">
      
      <img
        className=""
        id="iiqwdf"
        src="https://assetsprojects.s3.amazonaws.com/404p7ohlgva62bh.png"
        alt="undefined"
      />
      
      <input
        name="emailInputCalendar"
        type="email"
        placeholder="Correo*"
        className=""
        id="i0wnli"
      />
      
      </div>
      
      <div id="i3y5y2" className="">
      
      </div>
      
      </div>
      
      <div id="isxb6h" className="">
      
      <div id="i2n7p2" className="">
      <span>Mensaje</span>
      </div>
      
      <div id="ii16ot" className="">
      
      <img
        className=""
        id="i11bkl"
        src="https://assetsprojects.s3.amazonaws.com/404p7ohlgva6lk0.png"
        alt="undefined"
      />
      
      <input
        name="messageInputCalendar"
        type="text"
        placeholder="Mensaje"
        className=""
        id="iiyfny"
      />
      
      </div>
      
      <div id="idn20i" className="">
      
      </div>
      
      </div>
      
      <div id="is4169" className="">
      
      <div id="iohoaq" className="">
      <span>Teléfono</span>
      </div>
      
      <div id="iqjwot" className="">
      
      <img
        className=""
        id="iu2xxk"
        src="https://assetsprojects.s3.amazonaws.com/404p7ohlgva6sqk.png"
        alt="undefined"
      />
      
      <input
        name="phoneInputCalendar"
        type="text"
        placeholder="Teléfono*"
        className=""
        id="iw775j"
      />
      
      </div>
      
      <div id="ibxgxk" className="">
      
      </div>
      
      </div>
      
      <div id="imbka5" className="">
      
      <div id="iyvdgi" className="">
      <span>Dirección</span>
      </div>
      
      <div id="i7sn5f" className="">
      
      <img
        className=""
        id="i5ehug"
        src="https://assetsprojects.s3.amazonaws.com/404p7ohlgva706h.png"
        alt="undefined"
      />
      
      <input
        name="dirInputCalendar"
        type="text"
        placeholder="Dirección"
        className=""
        id="i5r0jl"
      />
      
      </div>
      
      <div id="isqlch" className="">
      
      </div>
      
      </div>
      
      <button
        type="submit"
        name=""
        id="ijarf7"
        className=""
        
      >
      <span>Reservar Cita</span>
      </button>
      
      </div>
      
      </form>
      
    </div>
  );
}
  
  
  return (
    <div id="id63af587624b3a1a08ba1af62" >

      <div id="ijl4" className="">
      
      <div id="i114ep" className="">
      
      <div id="i0a5qg" className="">
      
      <div id="ikgl4f" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#init"
        type=""
      >
        <span>Home</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#about"
        type=""
      >
        <span>About</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#team"
        type=""
      >
        <span>Team</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#contact"
        type=""
      >
        <span>Contact</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Twitter</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="it11um" className="">
      
      <p className="" id="i6as7l">
        
      <span className="" id="ix3ge1">
        <span>Acosta's</span>
      </span>
      
      </p>
      
      <img
        className=""
        id="i4m3vk"
        src="https://assetsprojects.s3.amazonaws.com/406450il5i9ewct.png"
        alt="undefined"
      />
      
      <p className="" id="icwl38">
        
      <span className="" id="igvz8y">
        <span>Lawfirm & Associates</span>
      </span>
      
      </p>
      
      </div>
      
      <div id="ilkxqj" className="">
      
        <a
          target="_blank"
          className=""
          id="i2m02f"
          href="#"
        >
          
      <p className="" id="iv0fz7">
        
      <a
        target=""
        className="link "
        id=""
        href="/"
        type=""
      >
        <span>Español</span>
      </a>
      
      </p>
      
        </a>
        
      <p className="" id="iyxyta">
        
      </p>
      
        <a
          target="_blank"
          className=""
          id="ipyf1z"
          href="#"
        >
          
      <p className="" id="iqu85r">
        
      <a
        target=""
        className="link "
        id=""
        href=""
        type=""
      >
        
      </a>
      
      <a
        target=""
        className="link "
        id=""
        href="/home"
        type=""
      >
        <span>Ingles</span>
      </a>
      
      </p>
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      <div id="init" className="">
      
      <div id="i9k4z" className="gjs-row ">
      
      <div id="ir2t8" className="gjs-cell ">
      
          <div id="is7b4" className="">
            <iframe
              title="Video Youtube"
              src="https://www.youtube.com/embed/3ioQw_KOr9g?"
              allowFullScreen="undefined"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </div>
          
      </div>
      
      <div id="i992y" className="gjs-cell ">
      
      <div id="ivtko" className="">
      
    <div id="" className="gjs-container container ">
    
      <div id="ipaf7" className="">
      
      <p className="" id="io9ap">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="ih1749">
        <span>Ética, Honestidad, Responsabilidad, Eficiencia, Lealtad, Justicia, Integridad, Respeto y Confidencialidad.</span>
      </span>
      
      </p>
      
      </div>
      
      <div id="i4yxi" className="">
      
      </div>
      
    </div>
    
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iiwh" className="">
      
      <div id="i4l02" className="gjs-row ">
      
      <div id="iwhfk" className="gjs-cell ">
      
      <p className="" id="iapoz">
        <span>Bienvenido a </span>
      </p>
      
      <p className="" id="irnm4">
        <span>Acosta's Law Firm</span>
      </p>
      
      </div>
      
      <div id="i1gx9" className="gjs-cell ">
      
      <div id="i959v7" className="">
      
      </div>
      
      <p className="" id="i39pw">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="ijoww">
        <span>Del año 1994 al año 2004, nos desempeñamos como asistentes de los connotados abogados y notarios David Alfonso Velásquez Mejía y Haroldo Arturo López en la ciudad de Tegucigalpa. Del año 2005 a la actualidad, instalamos nuestro despacho legal en la ciudad de Roatán, y desde entonces hemos desarrollado nuestras actividades siempre enmarcándonos en principios éticos, solidarios, de integridad, respeto y lealtad.
</span>
      </span>
      
      <br className="" id="itna5b" />
      
    <div id="ibegpz" className="">
    
      <span className="" id="iyfmlu">
        
      </span>
      
      <span className="" id="ign50c">
        
      </span>
      
      <span className="" id="iuyuj">
        <span>El futuro es prometedor y nos seguimos esforzando para que nuestro éxito se fundamente en la satisfacción de nuestros clientes.</span>
      </span>
      
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="about" className="">
      
      <div id="i8il5j" className="">
      
      <div id="ir0y3r" className="">
      
      <p className="" id="i930wg">
        <span>Ab</span>
      </p>
      
      </div>
      
      <div id="ixsm5i" className="">
      
      <p className="" id="iim50b">
        <span>- Initio</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ittzfj" className="gjs-row ">
      
      <div id="ixzslc" className="gjs-cell ">
      
      <div id="ifh71k" className="">
      
      <img
        className=""
        id="i6xc2n"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h0x0ac.png"
        alt="undefined"
      />
      
      <p className="" id="ieaqui">
        <span>Justicia</span>
      </p>
      
      <p className="" id="isaitc">
        <span>Principio moral que inclina a obrar y juzgar respetando la verdad y dando a cada uni lo que le corresponde.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iafvpq" className="gjs-cell ">
      
      <div id="ibwuaq" className="">
      
      <img
        className=""
        id="io7f8r"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h1frca.png"
        alt="undefined"
      />
      
      <p className="" id="ig0tug">
        <span>Ley</span>
      </p>
      
      <p className="" id="ib6yhk">
        <span>Es una declaración de la voluntad soberana, que manifestada en la forma prescrita por la constitución manda, prohíbe o permite</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ijybbx" className="gjs-cell ">
      
      <div id="i5fqoc" className="">
      
      <img
        className=""
        id="igb0i2"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h4uyhr.png"
        alt="undefined"
      />
      
      <p className="" id="iauejp">
        <span>Derecho</span>
      </p>
      
      <p className="" id="i9c1xx">
        <span>Es el conjunto de normas que imponen deberes y normas que confieren facultades, que establecen las bases de convivencia social y cuyo fun es dotar a todos los miembros de la sociedad de los minimos de seguridad, certeza, igualdad, libertad y justicia</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ifn8qn" className="gjs-cell ">
      
      <div id="iif82v" className="">
      
      <img
        className=""
        id="id6nnm"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h7ehwj.png"
        alt="undefined"
      />
      
      <p className="" id="ihokcc">
        <span>Confidencialidad</span>
      </p>
      
      <p className="" id="i7b7xz">
        <span>Conjunto de preceptos de caráter moral que debe siempre estar presentes en el hacer notarial del profesional autorizado para ejercer la función pública del Notariado.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ivbdjv" className="gjs-cell ">
      
      <div id="i8xmxg" className="">
      
      <img
        className=""
        id="ioj89n"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h8hqhg.png"
        alt="undefined"
      />
      
      <p className="" id="iwjy4v">
        <span>Notario</span>
      </p>
      
      <p className="" id="ifgsi5">
        <span>Profesional del derecho con carácter de fe pública, autorizado por el Estado para hacer constar la creación, transmisión modificación o extinción o resolución de actos, contratos y asuntos o negocios en que intervenga a requerimiento o petición de los interesados o por disposición de ley.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ih1xin" className="gjs-cell ">
      
      <div id="iyf67" className="">
      
      <img
        className=""
        id="id3u8"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h8saby.png"
        alt="undefined"
      />
      
      <p className="" id="ie17j">
        <span>Integridad</span>
      </p>
      
      <p className="" id="iwl9yl">
        <span>Una persona integra es aquella que siempre hace lo correcto, que hace todo aquello que considera bueno para la misma sin afectar los intereses de otros individuos.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iky5hi" className="gjs-cell ">
      
      <div id="i1uoff" className="">
      
      <p className="" id="itxqqt">
        <span>Conjunto de preceptos de caráter moral que debe siempre estar presentes en el hacer notarial del profesional autorizado para ejercer la función pública del Notariado.</span>
      </p>
      
      <img
        className=""
        id="i9gtav"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h958qv.png"
        alt="undefined"
      />
      
      <p className="" id="i2yiun">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="ivl4xq">
        <span>Ética</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="ikswhd" className="gjs-cell ">
      
      <div id="i97qdp" className="">
      
      <img
        className=""
        id="ipxuf7"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5h9n404.png"
        alt="undefined"
      />
      
      <p className="" id="idhw1w">
        <span>Responsabilidad</span>
      </p>
      
      <p className="" id="i7dq9v">
        <span>Es un valor y una práctica ética, ya que impacta en la vida familiar, académica, laboral y ciudadana.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i83dhi" className="gjs-cell ">
      
      <div id="i000fi" className="">
      
      <img
        className=""
        id="iojvny"
        src="https://assetsprojects.s3.amazonaws.com/405wf6hl5haj3fc.png"
        alt="undefined"
      />
      
      <p className="" id="ismbik">
        <span>Veracidad</span>
      </p>
      
      <p className="" id="i96hmo">
        <span>Es un valor moral positivo que busca la verdad</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="team" className="">
      
      <div id="ipiytl" className="">
      
      <div id="iqu2rt" className="">
      
      <p className="" id="ibcvzj">
        <span>Nuestro</span>
      </p>
      
      </div>
      
      <div id="ipxxa2" className="">
      
      <p className="" id="iyabfu">
        <span>Equipo</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iam2ci" className="gjs-row ">
      
      <div id="itclbu" className="gjs-cell ">
      
      <div id="ingz1b" className="">
      
      <div id="inp4q1" className="gjs-row ">
      
      <div id="i3kjf6" className="gjs-cell ">
      
      <img
        className=""
        id="ihzsor"
        src="https://assetsprojects.s3.amazonaws.com/406450il5i9ewct.png"
        alt="undefined"
      />
      
      <div id="isqixk" className="">
      
      </div>
      
      </div>
      
      <div id="ixyrcl" className="gjs-cell ">
      
      <div id="ijaal3" className="">
      
      <p className="" id="iw9tdw">
        <span>Mario Acosta</span>
      </p>
      
      <p className="" id="iie9mm">
        <span>Asuntos Notariales</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iwoxtl" className="">
      
      <div id="i0j0u4" className="">
      
      <div id="itmjq2" className="">
      
      <img
        className=""
        id="iorngw"
        src="https://assetsprojects.s3.amazonaws.com/406450il5iacbqn.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ij4jng" className="">
      
      <p className="" id="i2vhuk">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ie1g45">
        <span>+504 9551-0686
</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ihw0ie" className="gjs-cell ">
      
      <div id="inq8p6" className="">
      
      <div id="id8tik" className="gjs-row ">
      
      <div id="i8z5yi" className="gjs-cell ">
      
      <img
        className=""
        id="io4wwo"
        src="https://assetsprojects.s3.amazonaws.com/406450il5i9ewct.png"
        alt="undefined"
      />
      
      <div id="ivdyzy" className="">
      
      </div>
      
      </div>
      
      <div id="ij3lyh" className="gjs-cell ">
      
      <div id="isd8oi" className="">
      
      <p className="" id="iwvz3g">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="iqnw8z">
        <span>Romario Acosta</span>
      </span>
      
      </p>
      
      <p className="" id="ifenji">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="i6zl4l">
        <span>Asuntos Administrativos y Notariales
</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="isf544" className="">
      
      <div id="ib4qm6" className="">
      
      <div id="iqmdlg" className="">
      
      <img
        className=""
        id="ibjisg"
        src="https://assetsprojects.s3.amazonaws.com/406450il5iacbqn.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iraiys" className="">
      
      <p className="" id="if11mj">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="i30szm">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      <span> +504 9936-2872
</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ilr8fp" className="">
      
      <div id="ik4y1o" className="">
      
      <div id="ios5on" className="">
      
      <p className="" id="ixdbtg">
        <span>Nuestros</span>
      </p>
      
      </div>
      
      <div id="iho3im" className="">
      
      <p className="" id="iqu8rd">
        <span>Servicios</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i1vxgl" className="gjs-row ">
      
      <div id="if2sr8" className="gjs-cell ">
      
      <div id="i4bd9p" className="">
      
      <div id="ipl52i" className="">
      
      </div>
      
      <div id="i4b4t4" className="">
      
      <p className="" id="irtck9">
        <span>Asuntos Notariales</span>
      </p>
      
      <div id="ionvnj" className="">
      
        <a
          target=""
          className=""
          id="iz6ev7"
          href="/service"
        >
          
        </a>
        
      </div>
      
      <div id="iveut6" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i12igg" className="">
      
      <div id="i10f5f" className="">
      
      </div>
      
      <div id="ir1615" className="">
      
      <p className="" id="idvnf7">
        <span>Asuntos Administrativos en General</span>
      </p>
      
      <div id="iynw1a" className="">
      
        <a
          target=""
          className=""
          id="iesdb6"
          href="/service"
        >
          
        </a>
        
      </div>
      
      <div id="iobiu6" className="">
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i8w3cx" className="gjs-cell ">
      
      <div id="it97iq" className="">
      
      <div id="iyaah8" className="">
      
      </div>
      
      <div id="i93oc6" className="">
      
      <p className="" id="igmw4t">
        <span>Asuntos Judiciales</span>
      </p>
      
      <div id="ille9s" className="">
      
        <a
          target=""
          className=""
          id="ijkf9t"
          href="/service"
        >
          
        </a>
        
      </div>
      
      <div id="ig1ymb" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="idkulj" className="">
      
      <div id="i456ma" className="">
      
      </div>
      
      <div id="icwa41" className="">
      
      <p className="" id="i00ijp">
        <span>Asuntos de Familia</span>
      </p>
      
      <div id="inoj78" className="">
      
        <a
          target=""
          className=""
          id="iosw1z"
          href="/service"
        >
          
        </a>
        
      </div>
      
      <div id="izcoaj" className="">
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i3990z" className="">
      
      <div id="ieajah" className="">
      
      <div id="iy8gl1" className="">
      
      <p className="" id="ipo1c9">
        <span>Agendar</span>
      </p>
      
      </div>
      
      <div id="ivvhgh" className="">
      
      <p className="" id="irewm9">
        <span>Cita</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iojadu" className="">
      
      <div id="ii4yzf" className="">
      <PluginCalendar_i2tank />
      <div id="iuw1u4" className="">
      
      <p className="" id="irzfji">
        <span>Tu zona horaria: America/Tegucigalpa</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ic68xh" className="">
      
      <div id="iru0nz" className="">
      
      <div id="ic3h3i" className="">
      
      <p className="" id="ie2nuf">
        
    <div id="" className="">
    
    <div id="irh2id" className="">
    <span>Correo:</span>
    </div>
    
    <div id="i1tmbu" className="">
    
      <span className="" id="icenef">
        
      </span>
      
      <span className="" id="i7hjw5">
        
      </span>
      
      <span className="" id="ii7lc7">
        <span>info@acostaslawfirm.com</span>
      </span>
      
    </div>
    
    <div id="i8hitb" className="">
    
      <span className="" id="ifxwi5">
        
      <br className="" id="iiw5kh" />
      
      </span>
      
    </div>
    
    <div id="ir604w" className="">
    
      <span className="" id="i9kjvc">
        <span>Teléfono:</span>
      </span>
      
    </div>
    
    <div id="iku15t" className="">
    
      <span className="" id="i4k8dn">
        
      </span>
      
      <span className="" id="i25bki">
        
      </span>
      
      <span className="" id="i3oxbc">
        <span>+504 9936-2872, +504 9551-0686</span>
      </span>
      
    </div>
    
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="contact" className="">
      
      <p className="" id="i3fvqj">
        <span>Contact</span>
      </p>
      
      <div id="i933iv" className="">
      
      <p className="" id="is6mzq">
        <span>Contacto</span>
      </p>
      
        <div className="" id="io0oz8">
          
      <form
        id="iltufs"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					createcontact({
						fullname: e.target.fullname.value,
						email: e.target.email.value,
						comment: e.target.comment.value,
					});
					alert("Se agrego de manera exitosa el registro");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="igzs7j" className="">
      
      <label id="icl5q6" className="">
      <span>Nombre Completo *</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="fullname"
        type="text"
        placeholder=""
        className=""
        id="iggs68"
      />
      
      </div>
      
      <div id="iixdzv" className="">
      
      </div>
      
      <div id="imrbmj" className="">
      
      <label id="iy9jtg" className="">
      <span>Correo Electronico *</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="email"
        type="text"
        placeholder=""
        className=""
        id="ircszw"
      />
      
      </div>
      
      <div id="igkufl" className="">
      
      </div>
      
      <div id="ionbcj" className="">
      
      <label id="iiw12q" className="">
      <span>Comentario o Mensaje *</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="comment"
        type="text"
        placeholder=""
        className=""
        id="iysq96"
      />
      
      </div>
      
      <div id="ilnpaq" className="">
      
      </div>
      
      <button
        type="submit"
        name=""
        id="iehnrj"
        className=""
        
      >
      <span>
                Enviar</span>
      </button>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      </div>
      
      <div id="i123kk" className="">
      
      <iframe
        title="Mapa"
        className=""
        id="ijhnci"
        frameBorder="0"
        src="https://maps.google.com/maps?&q=Bojangles, McNab Plaza, main street, French Harbour&z=17&t=q&output=embed"
      />
      
      </div>
      
      <div id="i8bw3" className="">
      
      <div id="ic4k0a" className="">
      
      <div id="idapj2" className="">
      
      <img
        className=""
        id="inj60i"
        src="https://assetsprojects.s3.amazonaws.com/406450il5i9ewct.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ipog2k" className="">
      
      <div id="il59zj" className="">
      
      </div>
      
      <p className="" id="iajh9o">
        <span>Acosta's</span>
    <div id="" className="">
    <span>Lawfirm</span>
    </div>
    
    <div id="" className="">
    <span>& Associates</span>
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="i0exw3" className="">
      
      <p className="" id="ip30xz">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ivovhk">
        <span>Roatán,  Islas de la Bahía </span>
      <br className="" id="" />
      <span> Honduras</span>
      <br className="" id="" />
      <span>Bojangles, McNab Plaza, main street, French Harbour</span>
      <br className="" id="" />
      <span>Copyright © Acosta Law Firm 2022. Todos Los Derechos Reservados.</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Ppageinitesp);
  