
import React from 'react';

import {connect} from 'react-redux';



import './servicepage_eng.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pservicepage_eng = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id62fa7cbdfc8b2a0012c15bbe" >

      <div id="igll1i" className="">
      
      <div id="i80ug5" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className=""
        id="menu__toggle"
      />
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Home</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>About</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Team</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Contact</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Twitter</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ia5c5o" className="">
      
      <p className="" id="ileqxj">
        
      <span className="" id="i4d4go">
        <span>Acosta's</span>
      </span>
      
      </p>
      
      <img
        className=""
        id="i6dzmi"
        src="https://assetsprojects.s3.amazonaws.com/406450il5i9ewct.png"
        alt="undefined"
      />
      
      <p className="" id="im1e0j">
        
      <span className="" id="ixxam4">
        <span>Lawfirm & Associates</span>
      </span>
      
      </p>
      
      </div>
      
      <div id="iihyd1" className="">
      
      <p className="" id="i0ql2c">
        
      <a
        target="_blank"
        className="link "
        id=""
        href="/service"
        type=""
      >
        <span>Spanish</span>
      </a>
      
      </p>
      
      <p className="" id="i08uu7">
        
      </p>
      
      <p className="" id="if5ki3">
        
      <a
        target=""
        className="link "
        id=""
        href="/services_en"
        type=""
      >
        <span>English</span>
      </a>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="i8fj" className="">
      
      <div id="itt4x" className="">
      
      <div id="i8dn6" className="">
      
      <p className="" id="i88ni3">
        <span>Our</span>
      </p>
      
      </div>
      
      <div id="i7eni" className="">
      
      <p className="" id="im1qvd">
        <span>Services</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iulh4" className="">
      
      <div id="ilcz9l" className="">
      
      </div>
      
      <div id="iskol" className="">
      
      <div id="i5irz" className="">
      
      <div id="ivggr" className="">
      
      <p className="" id="ivsc7">
        <span>Notarial Matters</span>
      </p>
      
      <div id="i47ze" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ixflv" className="">
      
      <p className="" id="iafs7">
        <span>Civil Matters</span>
      </p>
      
      <div id="ib9mt" className="">
      
      </div>
      
      <div id="iu9ft" className="">
      
      <p className="" id="if3uf">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="iweju6">
        <span>Deeds of Sale.</span>
      </span>
      
    <div id="" className="">
    
      <span className="" id="iecnzg">
        <span>
Promise to Sale / Agreement.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="inkya1">
        <span>
Mortgage and mortgage cancellation.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="in7n4f">
        <span>
Donations.</span>
      </span>
      
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="i2tig6" className="">
      
      <p className="" id="i0vjo">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="i32zid">
        <span>Commercial Matters</span>
      </span>
      
      </p>
      
      <div id="iyw7tj" className="">
      
      </div>
      
      <div id="ioz3nl" className="">
      
      <p className="" id="ia5u8r">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="i8bnz7">
        <span>Establishment, Transformation or Dissolution of Companies (Corporations) (Capital Increase and Reduction).</span>
      </span>
      
    <div id="" className="">
    
      <span className="" id="iqmimv">
        <span>
Notarial Acts in General.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="ic2b18">
        <span>
Testaments.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="iap753">
        <span>
Herences and Legacies.</span>
      </span>
      
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="iy8bhi" className="">
      
      <p className="" id="iyqvpl">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ijdt4k">
        <span>Acts of Voluntary Jurisdiction at Notarial Premises</span>
      </span>
      
      </p>
      
      <div id="isflqg" className="">
      
      </div>
      
      <div id="ip3ng3" className="">
      
      <p className="" id="is1op3">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="if0f3z">
        <span>Registration and Rectification in the Civil Registry.</span>
      </span>
      
    <div id="" className="">
    
      <span className="" id="iy658g">
        <span>
Ad-Perpetuam Informations.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="ii0955">
        <span>
Marriages (Authorization of Marriages inside and outside Honduras, Marriage Capitulations).</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="ibu4kn">
        <span>
Divorce by Mutual Consent.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="ilir1u">
        <span>
Union and Separation Acts.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="ij2sg4">
        <span>
Authorizations in general.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="id2vss">
        <span>
Conciliation and Arbitration.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="ixl8pp">
        <span>
Partitioning of Property.</span>
      </span>
      
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="i37k8k" className="">
      
      <p className="" id="ip7h93">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="io761z">
        <span>Power of Attorney: Granting, Extension, Substitution and Revocation</span>
      </span>
      
      </p>
      
      <div id="i5xsb3" className="">
      
      </div>
      
      <div id="in8bzj" className="">
      
      <p className="" id="ijxrik">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="imt1yo">
        <span>Special Power.</span>
      </span>
      
    <div id="" className="">
    
      <span className="" id="ifmslq">
        <span>
General Power.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="ivhlb9">
        <span>
Power (Administration and Disposition).</span>
      </span>
      
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="isa4mk" className="">
      
      <p className="" id="iohh9g">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="i48ify">
        <span>Condominium Property</span>
      </span>
      
      </p>
      
      <div id="ijmwrn" className="">
      
      </div>
      
      <div id="irtdos" className="">
      
      <p className="" id="iqer13">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="i9dpwb">
        <span>For Constitution, Affectation of the Property.</span>
      </span>
      
    <div id="" className="">
    
      <span className="" id="i4m36h">
        <span>
Building Groupings.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="ie7qpf">
        <span>
Servitude (Constitution).</span>
      </span>
      
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="il2jp" className="">
      
      </div>
      
      </div>
      
      <div id="i9d40k" className="">
      
      <div id="i1jsxj" className="">
      
      </div>
      
      <div id="ilparc" className="">
      
      <div id="i8ws06" className="">
      
      <div id="iz9qir" className="">
      
      <p className="" id="i545qf">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="io13ji">
        <span>Administrative Matters in General
</span>
      </span>
      
      </p>
      
      <div id="ic11df" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iwkent" className="">
      
      <p className="" id="iaqq06">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="iod7bn">
        <span>Migratories</span>
      </span>
      
      </p>
      
      <div id="i1groc" className="">
      
      </div>
      
      <div id="i71avp" className="">
      
      <p className="" id="ifccq8">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="iqjtw2">
        <span>Residences.</span>
      </span>
      
    <div id="" className="">
    
      <span className="" id="iw6czl">
        <span>
Work Permits.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="i1e06h">
        <span>
Residence Permits on Grounds of Legitimate and Special Purpose.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="ilwelj">
        <span>
Legalization of Foreign Companies and Organizations.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="igx2uu">
        <span>
Legal Entity.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="i5f6pk">
        <span>
Fundations.</span>
      </span>
      
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="iemnxq" className="">
      
      <p className="" id="idf33t">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ick86t">
        <span>Tax, Administrative and Accounting Matters</span>
      </span>
      
      </p>
      
      <div id="i5r2wf" className="">
      
      </div>
      
      <div id="iph7ew" className="">
      
      <p className="" id="iqzcrj">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="ig2v8y">
        <span>Disputes and Lawsuits.</span>
      </span>
      
    <div id="" className="">
    
      <span className="" id="izykce">
        <span>
Financial Statements.</span>
      </span>
      
    </div>
    
    <div id="" className="">
    
      <span className="" id="iqlu2n">
        <span>
Representation before the SAR.</span>
      </span>
      
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iaqqq9" className="">
      
      <div id="i7alth" className="">
      
      </div>
      
      <div id="ihssh9" className="">
      
      <div id="i93i73" className="">
      
      <div id="i0ofpg" className="">
      
      <p className="" id="i2808z">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="idp5jx">
        <span>Judicial Matters
</span>
      </span>
      
      </p>
      
      <div id="io5l7g" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i7x1zj" className="">
      
      <p className="" id="igof0t">
        
      <span className="" id="">
        
      </span>
      <span>Divorce. </span>
    <div id="" className="">
    
      <br className="" id="" />
      
    </div>
    
    <div id="" className="">
    <span>Civil, commercial, labour and administrative lawsuits. </span>
    <div id="" className="">
    
      <br className="" id="" />
      
    <div id="" className="">
    <span>Complaints, Accusations and Defenses in Criminal Matters. </span>
    </div>
    
    <div id="" className="">
    
      <br className="" id="" />
      
    </div>
    
    <div id="" className="">
    <span>Representation in Administrative and Judicial Matters in General.</span>
    </div>
    
    </div>
    
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="ipjf3a" className="">
      
      </div>
      
      </div>
      
      <div id="i7xfof" className="">
      
      <div id="i9b58g" className="">
      
      </div>
      
      <div id="isofoo" className="">
      
      <div id="i8wcay" className="">
      
      <div id="iqnwly" className="">
      
      <p className="" id="i7ne5g">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="izejfs">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      <span>Family Matters
</span>
      </span>
      
      </p>
      
      <div id="izmnq1" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i6zsql" className="">
      
      <p className="" id="i66e7f">
        
      <span className="" id="">
        
      </span>
      <span>Child Recognition. </span>
    <div id="" className="">
    
      <br className="" id="" />
      
    </div>
    
    <div id="" className="">
    <span>Civil Marriage.</span>
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ijk5" className="">
      
      <iframe
        title="Mapa"
        className=""
        id="i0wk"
        frameBorder="0"
        src="https://maps.google.com/maps?&q=Bojangles, McNab Plaza, main street, French Harbour&z=17&t=q&output=embed"
      />
      
      </div>
      
      <div id="ixax" className="">
      
      <div id="if6id8" className="">
      
      <div id="ixamfy" className="">
      
      <img
        className=""
        id="iqx8xl"
        src="https://assetsprojects.s3.amazonaws.com/406450il5i9ewct.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i9tjsk" className="">
      
      <div id="il22am" className="">
      
      </div>
      
      <p className="" id="igni1j">
        <span>Acosta's</span>
    <div id="" className="">
    <span>Lawfirm </span>
    </div>
    
    <div id="" className="">
    <span>& Associates</span>
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="iacgmf" className="">
      
      <p className="" id="iu9123">
        
      <span className="" id="iaypss">
        <span>Roatán,  Islas de la Bahía </span>
      </span>
      
      <br className="" id="i8osim" />
      
      <span className="" id="i36x8h">
        <span> Honduras</span>
      </span>
      
      <br className="" id="i40iea" />
      
      <span className="" id="ie87k8">
        <span>Bojangles, McNab Plaza, main street, French Harbour</span>
      </span>
      
      <br className="" id="iha4e8" />
      
      <span className="" id="iaq2t7">
        <span>Copyright © Acosta Law Firm 2022. All Rights Reserved.</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Pservicepage_eng);
  