
import axios from '../config/axios';

export const url = '/api/contact'


export const createcontact = ({
  token,
	fullname,
	email,
	comment,
	loftyFile,

}) => new Promise((resolve, reject) => {
  if (token && fullname && email && comment ) {

    axios.post(`${url}//create/basic/63fcd75c1fe1c51baa1d770f`, {
		fullname,
		email,
		comment,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!fullname) {
		reject({
			status: 'error',
			info: 'fullname no esta definido',
		});
	}  else if (!email) {
		reject({
			status: 'error',
			info: 'email no esta definido',
		});
	}  else if (!comment) {
		reject({
			status: 'error',
			info: 'comment no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      

